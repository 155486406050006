<template>
  <div class="min-h-75vh h-auto mx-3 mb-10">
    <!-- form เข้าร่วมงาน -->
    <section>
      <!-- ช่วงเวลาเข้าร่วมงาน -->
      <div class="text-wedding-black mb-5">
        <p style="font-family: Anuphan_medium;">ช่วงเวลาเข้างาน</p>

        <div class="mt-5 px-3">
          <div v-if="signupForm.eventInfo.mode == 'morning' || signupForm.eventInfo.mode == 'morningAndEvening'" class="mb-5">
            <label class="inline-flex items-center">
              <input
                id="attendMorning"
                type="checkbox"
                class="form-checkbox border-gray-300 focus:ring-0"
                @change="handleAttendMoment('morning', $event)"
                :checked="guestData.morning.attend"
              >
              <span class="ml-2">ช่วงเช้า 
                ( <span v-if="signupForm.eventInfo.morning">{{ signupForm.eventInfo.morning.time}}</span> )
              </span>
            </label>
          </div>

          <div v-if="signupForm.eventInfo.mode == 'evening' || signupForm.eventInfo.mode == 'morningAndEvening'" class="mb-5">
            <label class="inline-flex items-center">
              <input
                id="attendEvening"
                type="checkbox"
                class="form-checkbox text-gray-500 border-wedding-cream focus:ring-0"
                @change="handleAttendMoment('evening', $event)"
                :checked="guestData.evening.attend"
              >
              <span class="ml-2">ช่วงเย็น 
                ( <span v-if="signupForm.eventInfo.evening">{{ signupForm.eventInfo.evening.time}}</span> )
              </span>
            </label>
          </div>
        </div>
      </div>

      <!-- จำนวนแขก -->
      <hr class="my-3">
      <div class="my-6">
        <p style="font-family: Anuphan_medium;">จำนวนแขกที่มากับท่าน (รวมตัวท่าน)</p>

        <div class="mt-5">
          <div v-if="signupForm.eventInfo.mode == 'morning' || signupForm.eventInfo.mode == 'morningAndEvening'" class="my-2">
            <div class="flex justify-between items-center">
              <p class="w-1/2">งานเช้า</p>
              <div  class="w-1/2">
                <input-count
                :count="guestData.morning.numberOfGuest"
                @counter="handleInputCounter('morning', $event)"
              />
              </div>
            </div>
          </div>

          <div v-if="signupForm.eventInfo.mode == 'evening' || signupForm.eventInfo.mode == 'morningAndEvening'" class="my-2">
            <div class="flex justify-between items-center">
              <p class="w-1/2">งานเย็น</p>
              <div class="w-1/2">
                <input-count
                :count="guestData.evening.numberOfGuest"
                @counter="handleInputCounter('evening', $event)"
              />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- แขกฝ่าย -->
      <hr v-if="(signupForm.eventInfo.mode == 'morning' || signupForm.eventInfo.mode == 'morningAndEvening') || (signupForm.eventInfo.mode == 'evening' || signupForm.eventInfo.mode == 'morningAndEvening')" class="my-3">
      <div
        v-if="(signupForm.eventInfo.mode == 'morning' || signupForm.eventInfo.mode == 'morningAndEvening') || (signupForm.eventInfo.mode == 'evening' || signupForm.eventInfo.mode == 'morningAndEvening')"
        class="text-wedding-black my-6"
      >
        <p style="font-family: Anuphan_medium;">แขกฝ่าย</p>

        <div class="flex justify-around mt-5">
          <button
            @click="handleGuestOf('groom')"
            class="h-28 flex-1 rounded-xl py-1 mx-1 focus:outline-none border-solid "
            :class="guestData.guestOf == 'groom' ? 'border-2 border-wedding-blue' : 'border border-gray'"
          >
            <div class="flex justify-center">
              <img src="@/assets/svg/icon-groom.svg"/>
            </div>
            <p>เจ้าบ่าว</p>
          </button>

          <button
            @click="handleGuestOf('bride')"
            class="h-28 flex-1 rounded-xl py-1 mx-1 focus:outline-none border-solid "
            :class="guestData.guestOf == 'bride' ? 'border-2 border-wedding-blue' : 'border border-gray'"
          >
            <div class="flex justify-center">
              <img src="@/assets/svg/icon-bride.svg"/>
            </div>
            
            <p>เจ้าสาว</p>
          </button>
        </div>
      </div>

      <!-- กลุ่มของ -->
      <div class="w-full" v-if="guestData.guestOf">
        <div class="grid grid-cols-2">
          <div class="flex justify-center">
            <svg width="32" height="15" viewBox="0 0 32 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.1716 1.82842L0 15H32L18.8284 1.82843C17.2663 0.26633 14.7337 0.266327 13.1716 1.82842Z" :fill="guestData.guestOf == 'groom' ? '#F4F9FD' : '#fff'"/>
            </svg>
          </div>

          <div class="flex justify-center">
            <svg width="32" height="15" viewBox="0 0 32 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.1716 1.82842L0 15H32L18.8284 1.82843C17.2663 0.26633 14.7337 0.266327 13.1716 1.82842Z" :fill="guestData.guestOf == 'bride' ? '#F4F9FD' : '#fff'"/>
            </svg>
          </div>
        </div>
      </div>

      <div
        v-if="guestData.guestOf"
        class="bg-wedding-skyblue rounded-2xl p-3"
      >
        <p style="font-family: Anuphan_medium;">กลุ่ม</p>

        <div class="flex flex-col mt-2">
          <label
            v-for="(value, nameSide) in signupForm.guestGroup[guestData.guestOf]"
            :key="nameSide"
            class="inline-flex items-center my-2"
          >
            <input
              type="radio"
              :value="value"
              @change="handleGroupOf"
              :checked="guestData.groupOf == value"
              class="form-radio text-gray-500 border-gray-300 focus:ring-0"
            >
            <span class="ml-2">{{ value }}</span>
          </label>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { computed, onBeforeMount } from 'vue'
import store from '../../store/index'
import { useRouter } from 'vue-router'

import InputCount from '../../components/Input/InputCount.vue'
import InputString from '../../components/Input/InputString.vue'

export default {
  components: {
    InputCount,
    InputString,
  },
  setup () {
    const router = useRouter()
    const guestData = computed(() => store.state.guest)
    const signupForm = computed(() => store.state.signupForm)
    const attend = computed(() => store.state.guest.attend)

    onBeforeMount(() => {
      store.commit('guest/setState', { key: 'attend', value: true })
    })

    const handleAttend = (val) => {
      if(val == false) router.push({ path: '/not-attend' })
      store.commit('guest/setState', { key: 'attend', value: val })
    }

    const handleAttendMoment = (momentWedding, event) => {
      const data = {
        ...guestData.value[momentWedding],
        attend: event.target.checked
      }
      store.commit('guest/setState', { key: momentWedding, value: data })
    }

    const handleGuestOf = (val) => {
      store.commit('guest/setState', { key: 'guestOf', value: val })
      store.commit('guest/setState', { key: 'groupOf', value: '' })
    }

    const handleGroupOf = (event) => {
      store.commit('guest/setState', { key: 'groupOf', value: event.target.value })
    }

    const handleInputCounter = (momentWedding, val) => {
      const data = {
        ...guestData.value[momentWedding],
        numberOfGuest: val
      }
      store.commit('guest/setState', { key: momentWedding, value: data })
    }

    return {
      guestData,
      signupForm,
      handleAttend,
      handleAttendMoment,
      handleGuestOf,
      handleGroupOf,
      handleInputCounter,

      attend,
    }
  }
}
</script>

<style>

</style>