<template>
  <div
    id="modalNotAttend"
    class="min-w-screen h-screen fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"
    :class="isShow ? '' : 'hidden'"
  >
    <!-- overlay -->
    <div class="absolute bg-black opacity-80 inset-0 z-0"></div>

    <!-- content -->
    <div
      class="w-full max-w-lg p-5 mx-3 sm:mx-auto relative my-auto rounded-xl shadow-lg  bg-white "
      :class="isShow ? 'showModal' : 'hideModal'"
    >
      <div>
        <!-- close button -->
        <div class="absolute right-5 top-5"  @click="cancel">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 1L1 15M1.00001 1L15 15" stroke="#0855CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        
        <!--body-->
        <div class="mb-5">
          <div class="text-center p-5 flex-auto justify-center">
            <div class="flex justify-center items-center">
              <img src="@/assets/svg/icon-not-attend.svg">

            </div>
            
            <div class="my-3" style="font-family: Anuphan_semibold;">
              <h3 class="text-lg">เสียใจจังที่คุณ</h3>
              <h3 class="text-lg">ไม่สามารถมาร่วมงานได้</h3>
            </div>

            <p class="text-sm text-gray-500">คุณสามารถเขียนคำอวยพรให้พวกเราผ่านระบบนี้ได้เลย ขอบคุณที่ให้ความสนใจในพิธีมงคลของเรา</p>
          </div>
        </div>

        <!--footer-->
        <div class="mt-10 text-center space-x-4 md:block">
          <button 
            @click="confirm"
            class="w-full h-12 px-6 text-white transition-colors duration-150 bg-wedding-blue rounded-full focus:shadow-outline"
          >
            เขียนคำอวยพร
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
  },
  setup (props, { emit }) {
    const cancel = () => {
      emit('cancel')
    }

    const confirm = () => {
      emit('confirm', false)
    }

    return {
      cancel,
      confirm
    }
  }
}
</script>

<style scoped>
.showModal {
  animation: fadeDown 0.5s ease-in-out both;
}

.hideModal {
  animation: fadeUp 0.3s ease-in both;
}

@keyframes fadeUp {
	from {
    opacity: 1;
		transform: translate3d(0, 0, 0);
	}
	to {
    opacity: 0;
		transform: translate3d(0, -20%, 0);
	}
}

@keyframes fadeDown {
	from  {
		opacity: 0;
    transform: translate3d(0, 20%, 0);
	}
	to {
		opacity: 1;
    transform: translate3d(0, 0, 0);
	}
}
</style>