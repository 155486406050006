<template>
  <div
    id="modalImageCard"
    class="min-w-screen h-screen fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"
    :class="isShow ? '' : 'hidden'"
  >
    <!-- overlay -->
    <div class="absolute bg-black opacity-80 inset-0 z-0"></div>

    <!-- content -->
    <div
      class="w-full max-w-lg p-5 mt-5 mx-3 sm:mx-auto relative my-auto rounded-xl shadow-lg  bg-white"
      :class="isShow ? 'showModal' : 'hideModal'"
      style="max-height: 95vh; height: auto; overflow: scroll;"
    >
      <div>
        <!-- header -->
        <div class="">
          <p class="text-center text-2xl">การ์ดเชิญ</p>
          <div class="absolute right-5 top-5"  @click="cancel">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 1L1 15M1.00001 1L15 15" stroke="#0855CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>

        <!--body-->
        <div class="mb-5">
          <div class="text-center pt-5 flex justify-center">
            <img :src="imageUrl" alt="image card"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    imageUrl: {
      type: String
    }
  },
  setup (props, { emit }) {
    const cancel = () => {
      emit('cancel')
    }

    return {
      cancel
    }
  }
}
</script>

<style scoped>
.showModal {
  animation: fadeDown 0.5s ease-in-out both;
}

.hideModal {
  animation: fadeUp 0.3s ease-in both;
}

@keyframes fadeUp {
	from {
    opacity: 1;
		transform: translate3d(0, 0, 0);
	}
	to {
    opacity: 0;
		transform: translate3d(0, -20%, 0);
	}
}

@keyframes fadeDown {
	from  {
		opacity: 0;
    transform: translate3d(0, 20%, 0);
	}
	to {
		opacity: 1;
    transform: translate3d(0, 0, 0);
	}
}
</style>