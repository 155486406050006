<template>
  <div
    class="flex flex-row justify-end items-center h-10 w-full relative bg-transparent mt-1"
  >
    <button
      @click="decrement"
      class="bg-white h-full cursor-pointer"
    >
      <span class="m-auto text-lg text-wedding-blue  border border-gray px-2 py-0.5 rounded-md">−</span>
    </button>

    <!-- <input
      v-model="counter"
      style="font-family: DM_Sans_medium; width: 40px;"
      type="number"
      class="text-center w-full bg-white border-transparent text-sm text-wedding-blue outline-none focus:ring-0 focus:border-transparent"
      name="custom-input-number" 
      disabled
    /> -->

      <span class="mx-4"> {{counter}} ท่าน</span>

    <button
      @click="increment"
      class="bg-white h-full cursor-pointer"
    >
      <span class="m-auto text-lg text-wedding-blue  border border-gray px-2 py-0.5 rounded-md">+</span>
    </button>
  </div>
</template>

<script>
import { ref, onBeforeMount, watch } from 'vue'

export default {
  props: ['count'],
  setup (props, { emit }) {
    let counter = ref(0)
    
    onBeforeMount(() => {
      if (props.count) counter.value = props.count
    })

    watch(() => props.count, (myVal) => {
      counter.value = myVal
    })

    const decrement = () => {
      if(counter.value <= 0) return
      counter.value--
      emit('counter', counter.value)
    }

    const increment = () => {
      counter.value++
      emit('counter', counter.value)
    }

    return {
      counter,
      decrement,
      increment
    }
  }
}
</script>

<style>

</style>