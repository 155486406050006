const formatDayToThai = (ddd) => {
  switch (ddd) {
    case 'Mon': {
      return 'วันจันทร์'
    }
    case 'Tue': {
      return 'วันอังคาร'
    }
    case 'Wed': {
      return 'วันพุธ'
    }
    case 'Thu': {
      return 'วันพฤหัสบดี'
    }
    case 'Fri': {
      return 'วันศุกร์'
    }
    case 'Sat': {
      return 'วันเสาร์'
    }
    case 'Sun': {
      return 'วันอาทิตย์'
    }
  }
}

const formatMonthToThai = (MMM) => {
  switch (MMM) {
    case 'Jan': {
      return 'มกราคม'
    }
    case 'Feb': {
      return 'กุมภาพันธ์'
    }
    case 'Mar': {
      return 'มีนาคม'
    }
    case 'Apr': {
      return 'เมษายน'
    }
    case 'Jun': {
      return 'พฤษภาคม'
    }
    case 'Jul': {
      return 'มิถุนายน'
    }
    case 'Aug': {
      return 'สิงหาคม'
    }
    case 'Sep': {
      return 'กันยายน'
    }
    case 'Oct': {
      return 'ตุลาคม'
    }
    case 'Nov': {
      return 'พฤศจิกายน'
    }
    case 'Dec': {
      return 'ธันวาคม'
    }
  }
}

export {
  formatDayToThai,
  formatMonthToThai
}