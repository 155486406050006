<template>
  <div
    :class="!data ? 'empty' : ''"
    class="relative input-component mt-4"
  >
    <input
      :id="idInput"
      :name="idInput"
      :value="data"
      :maxlength="maxChars"
      :disabled="disable"
      :class="genClassDisable"
      @input="handleInput"
      type="text"
      class="h-full w-full border-wedding-cream text-gray-500 px-2 transition-all border-blue rounded-sm focus:ring-0 focus:border-wedding-cream"
    />
    <label :for="idInput" class="absolute left-0 transition-all bg-transparent rounded-full px-1 text-gray-500">
      {{ label }}
    </label>
  </div>
</template>

<script>
import { computed } from 'vue'
import { makeid } from '../../utility/functions/makeId'

export default {
  props: {
    data: {
      type: String,
      require: true
    },
    label: {
      type: String,
      default: ''
    },
    maxChars: {
      type: String,
      default: '200'
    },
    disable: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const idInput = computed(() => makeid(4))
    const genClassDisable = computed(() => {
      return props.disable ? 'bg-gray-200' : ''
    })

    const handleInput = (event) => {
      const val = event.target.value
      emit('onInput', val)
    }

    return {
      idInput,
      handleInput,
      genClassDisable
    }
  }
}
</script>

<style scoped>
label {
  top: -25%;
  transform: translateY(-50%);
  font-size: 11px;
}

.empty input:not(:focus) + label {
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 14px;
}
</style>