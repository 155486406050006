<template>
  <div>
    <div class="rounded-t-2xl rounded-b-md overflow-hidden m-3" style="background-color: #f5f5f5;">
      <div class="py-1.5 px-5 bg-wedding-pink text-white text-center">{{ title }}</div>

      <div class="p-5">
        <!-- date -->
        <div class="flex mb-5">
          <div class="mr-5">
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 1V5M6 1V5M1 9H17M17 9V17C17 18.1046 16.1046 19 15 19H3C1.89543 19 1 18.1046 1 17V5C1 3.89543 1.89543 3 3 3H15C16.1046 3 17 3.89543 17 5V9Z" stroke="#FF1D6F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>

          <div>
            <p> {{ dateThai }}</p>
          </div>
        </div>

        <!-- time -->
        <div class="flex mb-5">
          <div class="mr-5">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 5V10L13 13M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" stroke="#FF1D6F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>

          <div>
            <p>เวลา {{ information.time }} น.</p>
          </div>
        </div>

        <!-- address -->
        <div class="flex mb-5">
          <div class="mr-5">
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 9C17 13.4183 13.4183 17 9 19C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z" stroke="#FF1D6F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 9C12 10.6569 10.6569 12 9 12C7.34315 12 6 10.6569 6 9C6 7.34315 7.34315 6 9 6C10.6569 6 12 7.34315 12 9Z" stroke="#FF1D6F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>

          <div>
            <p>{{ information.location.name }}</p>
            <p v-if="information.room">
              ห้อง {{ information.room }},
              <span v-if="information.floor">
                ชั้น {{ information.floor }}
              </span>
            </p>
            <p class="text-sm mt-3" style="color: #A6A6A6;"> ณ {{ information.location.address }}</p>

            <div class="my-5" >
              <div class="w-max flex items-center text-wedding-blue" @click="openMap">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 3L1 1V17L7 19M7 3L13 1M7 3V19M13 1L19 3V19L13 17M13 1V17M13 17L7 19" stroke="#0855CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span  class="ml-3" style="font-family: Anuphan_medium;">ดู Google Map</span>
              </div>
            </div>
          </div>
        </div>

        <!-- theme -->
        <div class="flex mb-5" v-if="information.theme" >
          <div class="mr-5">
            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 4L4 1H7C7 1.39397 7.0776 1.78407 7.22836 2.14805C7.37913 2.51203 7.6001 2.84274 7.87868 3.12132C8.15726 3.3999 8.48797 3.62087 8.85195 3.77164C9.21593 3.9224 9.60603 4 10 4C10.394 4 10.7841 3.9224 11.1481 3.77164C11.512 3.62087 11.8427 3.3999 12.1213 3.12132C12.3999 2.84274 12.6209 2.51203 12.7716 2.14805C12.9224 1.78407 13 1.39397 13 1H16L19 4L18.5 9L16 7.5V17H4V7.5L1.5 9L1 4Z" stroke="#FF1D6F" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
          </div>

          <div>
            <p> ธีมงาน {{ information.theme }}</p>
          </div>
        </div>

        <!-- foods -->
        <div class="flex mb-5" v-if="information.foods">
          <div class="mr-5">
            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 18H14M10 18V14M10 14C7.23858 14 5 11.7614 5 9V1H15V9C15 11.7614 12.7614 14 10 14ZM15 3H16.5C17.8807 3 19 4.11929 19 5.5C19 6.88071 17.8807 8 16.5 8H15M5 8H3.5C2.11929 8 1 6.88071 1 5.5C1 4.11929 2.11929 3 3.5 3L5 3" stroke="#FF1D6F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>

          <div>
            <p>
              อาหาร
              <span v-for="(food, index) in information.foods" :key="index" class="pl-1">
                {{ food }}, 
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import dayjs from 'dayjs'
import { formatDayToThai, formatMonthToThai } from '../utility/functions/format'

export default {
  props: {
    title: {
      type: String,
    },
    information: {
      type: Object,
      require: true
    }
  },
  setup (props) {
    const dateThai = computed(() => {
      const { date: isoString } = props.information
      const ref = dayjs(isoString)
      const day = formatDayToThai(ref.format('ddd'))
      const date = ref.format('YYYY/MMM/D')
      const [YYYY, MMM, D] = date.split('/')
      const month = formatMonthToThai(MMM)

      return `${day}ที่ ${D} ${month} พ.ศ. ${Number.parseInt(YYYY) + 543}`
    })

    const openMap = () => {
      const { url } = props.information.location
      window.open(url, '_blank')
    }

    return {
      dateThai,
      openMap
    }
  }
}
</script>

<style>

</style>