<template>
  <div>
    <menu-top
      :labelNav="currentStep == 1 ? 'รายละเอียด' : currentStep == 2 ? 'เข้าร่วมงาน' : 'ข้อมูลผู้ลงทะเบียน'"
    />
    <!-- modal -->
    <modal-confirm 
      :isShow="isModal"
      :guestData="guestData"
      labelBtn="ยืนยันการลงทะเบียน"
      @cancel="isModal = false"
      @confirm="save"
    />

    <div class="container mt-5 sm:w-96 mx-auto">
      <detail
        v-if="currentStep == 1"
        :submitNext="submitNext"
      />

      <participation-form
        v-if="currentStep == 2"
      />

      <guest-information-form
        v-if="currentStep == 3"
      />
    </div>
  </div>
</template>

<script>
import store from '../../store'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

import ModalConfirm from '../../components/ModalConfirm.vue'
import MenuTop from '../../components/menu-top.vue'
import Detail from './detail.vue'
import ParticipationForm from './participation-form.vue'
import guestInformationForm from './guest-information-form.vue'

export default {
  components: {
    ModalConfirm,
    MenuTop,
    Detail,
    ParticipationForm,
    guestInformationForm
  },

  setup () {
    const router = useRouter()

    const eventInfo = computed(() => store.state.signupForm.eventInfo)
    const guestData = computed(() => store.state.guest)
    const guestMorningData = computed(() => store.state.guest.morning)
    const guestEveningData = computed(() => store.state.guest.evening)

    const isInvalidStep2 = computed(() => {
      if(eventInfo.value.mode == 'morning' && guestMorningData.value.attend == false) return true

      if(eventInfo.value.mode == 'evening' && guestEveningData.value.attend == false) return true

      if(eventInfo.value.mode == 'morningAndEvening' && guestMorningData.value.attend == false && guestEveningData.value.attend == false) return true

      if(eventInfo.value.mode == 'morning' && guestMorningData.value.numberOfGuest == 0) return true

      if(eventInfo.value.mode == 'evening' && guestEveningData.value.numberOfGuest == 0) return true

      if(eventInfo.value.mode == 'morningAndEvening') {
        if(guestMorningData.value.attend == true && guestMorningData.value.numberOfGuest == 0) return true
        if(guestEveningData.value.attend == true && guestEveningData.value.numberOfGuest == 0) return true
      }

      if(guestData.value.guestOf == '' || !guestData.value.guestOf) return true

      if(guestData.value.groupOf == '' || !guestData.value.groupOf) return true

      return false
    })

    const isInvalidStep3 = computed(() => {
      if(guestData.value.firstName == '' || !guestData.value.firstName) return true
      if(guestData.value.lastName == '' || !guestData.value.lastName) return true
      if(guestData.value.tel == '' || !guestData.value.tel) return true
      if(guestData.value.address == '' || !guestData.value.address) return true
      return false
    })

    const currentStep = ref(1)
    let isModal = ref(false)

    const submitPrev = () => {
      if(currentStep.value <= 1) {
        router.back()
        return
      }
      currentStep.value -= 1
    }

    const submitNext = () => {
      if(currentStep.value >= 3) {
        isModal.value = true
        return
      }
      if(currentStep.value == 2) {

      }
      currentStep.value += 1
    }

    const save = async () => {
      isModal.value = false
      await store.dispatch('guest/saveToCloud')
      router.push('/thanks')
    }

    return {
      currentStep,
      isModal,
      submitPrev,
      submitNext,
      save,
      guestData,
      isInvalidStep2,
      isInvalidStep3
    }
  }
}
</script>

<style>

</style>